import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "general",
    title: "General",
    type: "item",
    icon: <Icon.Eye size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/"
  },
  {
    id: "dashboard-1",
    title: "Visión General",
    type: "item",
    icon: <Icon.BarChart size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/general"
  },
  {
    id: "uploads",
    title: "Cuentas",
    type: "item",
    icon: <Icon.Database size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/Manejo Cuentas"
  },
  {
    id: "users",
    title: "Usuarios",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["admin"],
    navLink: "/users"
  }
]

export default navigationConfig
